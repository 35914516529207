import React from "react"
// Components
import CreditApp from "../components/creditApp"
import Layout from "../components/layout"
import SEO from "../SEO"
import GA from "../components/ga"

export default () => {
  return (
    <>
      <GA />
      <Layout>
        <SEO title="Brophy Brothers | Credit Application" />
        <CreditApp />
      </Layout>
    </>
  )
}
