import React, { Component } from "react"
import axios from "axios"
import { FaTimes } from "react-icons/fa"
import Slide from "react-reveal/Slide"
import CheckMark from "./forms/valid"
import styles from "./forms/forms.module.scss"

class newForm extends Component {
  constructor(props) {
    super(props)
    this.state = this.getInitialState()
  }

  getInitialState = () => {
    const initalState = {
      loading: false,
      // Misc
      refTwo: false,
      refThree: false,
      // Section One
      contactName: "",
      contactNameValid: null,
      companyName: "",
      companyNameValid: null,
      phone: "",
      phoneValid: null,
      email: "",
      emailValid: null,
      fedTaxId: "",
      fedTaxIdValid: null,
      ssn: "",
      ssnValid: null,
      primaryAddress: "",
      primaryAddressValid: null,
      businessType: "",
      // Section Two
      yearsInBusiness: "",
      yearsInBusinessValid: null,
      howLong: "",
      howLongValid: null,
      differentEntity: "",
      differentEntityValid: null,
      differentLocation: "",
      differentLocationValid: null,
      businessPhone: "",
      businessPhoneValid: null,
      bankName: "",
      bankNameValid: null,
      primaryBusinessAddress: "",
      primaryBusinessAddressValid: null,
      primaryBusinessPhone: "",
      primaryBusinessPhoneValid: null,
      accountNumber: "",
      accountNumberValid: null,
      bankAccountType: "",
      // Section Three
      companyName0: "",
      companyName0Valid: null,
      companyName1: "",
      companyName1Valid: null,
      companyName2: "",
      companyName2Valid: null,
      nameOfContact0: "",
      nameOfContact0Valid: null,
      nameOfContact1: "",
      nameOfContact1Valid: null,
      nameOfContact2: "",
      nameOfContact2Valid: null,
      phone0: "",
      phone0Valid: null,
      phone1: "",
      phone1Valid: null,
      phone2: "",
      phone2Valid: null,
      address0: "",
      address0Valid: null,
      address1: "",
      address1Valid: null,
      address2: "",
      address2Valid: null,
      email0: "",
      email0Valid: null,
      email1: "",
      email1Valid: null,
      email2: "",
      email2Valid: null,
      // Section Four
      nameAndTitle: "",
      nameAndTitleValid: null,
      date: "",
      dateValid: null,
    }
    return initalState
  }

  resetState = () => {
    this.setState(this.getInitialState())
  }

  handleForm = evt => {
    evt.persist()
    const isValid = evt.target.checkValidity()

    this.setState({
      [evt.target.name]: evt.target.value,
      [`${[evt.target.name]}Valid`]: isValid,
    })
  }

  addFields = () => {
    if (this.state.refTwo) {
      this.setState({ refThree: true })
    } else {
      this.setState({ refTwo: true })
    }
  }
  removeReference = id => {
    if (id === 2) {
      this.setState({
        companyName1: "",
        companyName1Valid: null,
        nameOfContact1: "",
        nameOfContact1Valid: null,
        phone1: "",
        phone1Valid: null,
        address1: "",
        address1Valid: null,
        email1: "",
        email1Valid: null,
        refTwo: false,
      })
    } else {
      this.setState({
        companyName2: "",
        companyName2Valid: null,
        nameOfContact2: "",
        nameOfContact2Valid: null,
        phone2: "",
        phone2Valid: null,
        address2: "",
        address2Valid: null,
        email2: "",
        email2Valid: null,
        refThree: false,
      })
    }
  }

  handleSubmit = evt => {
    evt.preventDefault()
    this.setState({ loading: true })
    const state = this.state

    if (state.nameOfContact0) {
      var ref1 = {
        nameofContact0: state.nameOfContact0,
        companyName0: state.companyName0,
        phone0: state.phone0,
        address0: state.address0,
        email0: state.email0,
      }
    }
    if (state.nameOfContact1) {
      var ref2 = {
        nameofContact1: state.nameOfContact1,
        companyName1: state.companyName1,
        phone1: state.phone1,
        address1: state.address1,
        email1: state.email1,
      }
    }
    if (state.nameOfContact2) {
      var ref3 = {
        nameofContact2: state.nameOfContact2,
        companyName2: state.companyName2,
        phone2: state.phone2,
        address2: state.address2,
        email2: state.email2,
      }
    }

    const data = {
      contactName: state.contactName,
      companyName: state.companyName,
      phone: state.phone,
      email: state.email,
      fedTaxId: state.fedTaxId,
      ssn: state.ssn,
      primaryAddress: state.primaryAddress,
      businessType: state.businessType,
      yearsInBusiness: state.yearsInBusiness,
      howLong: state.howLong,
      differentEntity: state.differentEntity || "N/A",
      differentLocation: state.differentLocation || "N/A",
      businessPhone: state.businessPhone,
      bankName: state.bankName,
      primaryBusinessAddress: state.primaryBusinessAddress,
      primaryBusinessPhone: state.primaryBusinessPhone,
      accountNumber: state.accountNumber,
      bankAccountType: state.bankAccountType,
      reference1: ref1 || {},
      reference2: ref2 || {},
      reference3: ref3 || {},
      nameAndTitle: state.nameAndTitle,
      date: state.date,
    }

    const axiosConfig = {
      header: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    }
    axios
      .post("/.netlify/functions/creditEmail", data, axiosConfig)
      .then(response => {
        if (response.status === 200) {
          this.resetState()
          this.setState({
            infoMsg:
              "Thank you for your application, we will get back to you shortly. We have sent you a copy for your own records.",
            loading: false,
          })
          setTimeout(() => {
            this.setState({
              infoMsg: "",
            })
          }, 10000)
        }
      })
      .catch(err => {
        this.setState({
          infoMsg: "Opps, something went wrong. Please try again.",
        })
        setTimeout(() => {
          this.setState({
            infoMsg: "",
          })
        }, 10000)
      })
  }

  render() {
    return (
      <section className="section" style={{ background: `#f3f3f3` }}>
        <form
          onSubmit={this.handleSubmit}
          className={`${styles.form} ${styles.creditForm}`}
        >
          <fieldset>
            <h1>Brophy Brothers Credit Application</h1>

            <p>
              Your information and privacy are important to us, we do not store
              your personal information on this website so be sure not to
              navigate away or refresh this page while filling out this form.
            </p>
            <h4>Business Contact Information</h4>
            <div role="group">
              <label htmlFor="contactName">Contact Name / Title</label>
              <div className={styles.input}>
                <input
                  type="text"
                  name="contactName"
                  required
                  onChange={this.handleForm}
                  value={this.state.contactName}
                  id="contactName"
                  pattern=".{3,}"
                />
                <CheckMark value={this.state.contactNameValid} />
              </div>
            </div>
            <div role="group">
              <label htmlFor="companyName">Company Name</label>
              <div className={styles.input}>
                <input
                  type="text"
                  name="companyName"
                  required
                  onChange={this.handleForm}
                  value={this.state.companyName}
                  id="companyName"
                  pattern=".{3,}"
                />
                <CheckMark value={this.state.companyNameValid} />
              </div>
            </div>
            <div role="group">
              <label htmlFor="phone">Phone</label>
              <div className={styles.input}>
                <input
                  type="tel"
                  name="phone"
                  required
                  onChange={this.handleForm}
                  value={this.state.phone}
                  id="phone"
                  pattern=".{10,}"
                />
                <CheckMark value={this.state.phoneValid} />
              </div>
            </div>
            <div role="group">
              <label htmlFor="email">Email</label>
              <div className={styles.input}>
                <input
                  type="email"
                  name="email"
                  required
                  onChange={this.handleForm}
                  value={this.state.email}
                  id="email"
                />
                <CheckMark value={this.state.emailValid} />
              </div>
            </div>
            <div role="group">
              <label htmlFor="fedTaxId">Fed Tax Id</label>
              <div className={styles.input}>
                <input
                  type="text"
                  name="fedTaxId"
                  required
                  onChange={this.handleForm}
                  value={this.state.fedTaxId}
                  id="fedTaxId"
                  pattern="^(0[1-6]|1[0-6]|2[0-7]|[35][0-9]|[468][0-8]|9[0-5|8-9]|7[1-7])-?\d{7}$"
                  placeholder="12-3456789"
                />
                <CheckMark value={this.state.fedTaxIdValid} />
              </div>
            </div>
            <div role="group">
              <label htmlFor="ssn">Social Security Number</label>
              <div className={styles.input}>
                <input
                  type="text"
                  name="ssn"
                  required
                  onChange={this.handleForm}
                  value={this.state.ssn}
                  id="ssn"
                  pattern="^(\d{3}-?\d{2}-?\d{4}|XXX-XX-XXXX)$"
                  placeholder="123-45-6789"
                />
                <CheckMark value={this.state.ssnValid} />
              </div>
            </div>
            <div role="group">
              <label htmlFor="primaryAddress">
                Primary Address, City, State, Zip Code
              </label>
              <div className={styles.input}>
                <input
                  type="text"
                  name="primaryAddress"
                  required
                  onChange={this.handleForm}
                  value={this.state.primaryAddress}
                  id="primaryAddress"
                  pattern=".{3,}"
                />
                <CheckMark value={this.state.primaryAddressValid} />
              </div>
            </div>
            <div role="group">
              <label htmlFor="soleProprietorship">Type of Business</label>
              <div className={`${styles.input} ${styles.radio}`}>
                <label htmlFor="soleProprietorship ">Sole Proprietorship</label>
                <input
                  type="radio"
                  name="businessType"
                  id="soleProprietorship"
                  required
                  value="Sole Proprietorship"
                  onChange={this.handleForm}
                />
              </div>
              <div className={`${styles.input} ${styles.radio}`}>
                <label htmlFor="partnership">Partnership</label>
                <input
                  type="radio"
                  name="businessType"
                  id="partnership"
                  required
                  value="Partnership"
                  onChange={this.handleForm}
                />
              </div>
              <div className={`${styles.input} ${styles.radio}`}>
                <label htmlFor="corporation">Corporation</label>
                <input
                  type="radio"
                  name="businessType"
                  id="corporation"
                  required
                  value="Corporation"
                  onChange={this.handleForm}
                />
              </div>
            </div>
          </fieldset>

          <fieldset>
            <h4>Business Location & Bank Information</h4>
            <div role="group">
              <label htmlFor="yearsInBusiness">Years in Business</label>
              <div className={styles.input}>
                <input
                  type="number"
                  name="yearsInBusiness"
                  required
                  onChange={this.handleForm}
                  value={this.state.yearsInBusiness}
                  id="yearsInBusiness"
                  pattern="{1,}"
                />
                <CheckMark value={this.state.yearsInBusinessValid} />
              </div>
            </div>
            <div role="group">
              <label htmlFor="howLong">How long at the current address?</label>
              <div className={styles.input}>
                <input
                  type="number"
                  name="howLong"
                  required
                  onChange={this.handleForm}
                  value={this.state.howLong}
                  id="howLong"
                  pattern="{1,}"
                />
                <CheckMark value={this.state.howLongValid} />
              </div>
            </div>
            <div role="group">
              <label htmlFor="differentEntity">
                Was entity ever in business under a different name? If yes,
                what?
              </label>
              <div className={styles.input}>
                <input
                  type="text"
                  name="differentEntity"
                  onChange={this.handleForm}
                  value={this.state.differentEntity}
                  id="differentEntity"
                />
                <CheckMark value={this.state.differentEntityValid} />
              </div>
            </div>
            <div role="group">
              <label htmlFor="differentLocation">
                Was entity ever in business in different location? If yes,
                where?
              </label>
              <div className={styles.input}>
                <input
                  type="text"
                  name="differentLocation"
                  onChange={this.handleForm}
                  value={this.state.differentLocation}
                  id="differentLocation"
                />
                <CheckMark value={this.state.differentLocationValid} />
              </div>
            </div>
            <div role="group">
              <label htmlFor="businessPhone">Phone</label>
              <div className={styles.input}>
                <input
                  type="tel"
                  name="businessPhone"
                  required
                  onChange={this.handleForm}
                  value={this.state.businessPhone}
                  id="businessPhone"
                  pattern=".{10,}"
                />
                <CheckMark value={this.state.businessPhoneValid} />
              </div>
            </div>
            <div role="group">
              <label htmlFor="bankName">Bank Name</label>
              <div className={styles.input}>
                <input
                  type="text"
                  name="bankName"
                  required
                  onChange={this.handleForm}
                  value={this.state.bankName}
                  id="bankName"
                  pattern=".{1,}"
                />
                <CheckMark value={this.state.bankNameValid} />
              </div>
            </div>
            <div role="group">
              <label htmlFor="primaryBusinessAddress">
                Primary Business Address, City, State, Zip Code
              </label>
              <div className={styles.input}>
                <input
                  type="text"
                  name="primaryBusinessAddress"
                  required
                  onChange={this.handleForm}
                  value={this.state.primaryBusinessAddress}
                  id="primaryBusinessAddress"
                  pattern=".{1,}"
                />
                <CheckMark value={this.state.primaryBusinessAddressValid} />
              </div>
            </div>
            <div role="group">
              <label htmlFor="primaryBusinessPhone">Phone</label>
              <div className={styles.input}>
                <input
                  type="tel"
                  name="primaryBusinessPhone"
                  required
                  onChange={this.handleForm}
                  value={this.state.primaryBusinessPhone}
                  id="primaryBusinessPhone"
                  pattern=".{10,}"
                />
                <CheckMark value={this.state.primaryBusinessPhoneValid} />
              </div>
            </div>

            <div role="group">
              <label htmlFor="accountNumber">Account Number</label>
              <div className={styles.input}>
                <input
                  type="number"
                  name="accountNumber"
                  required
                  onChange={this.handleForm}
                  value={this.state.accountNumber}
                  id="accountNumber"
                  pattern=".{3,}"
                />
                <CheckMark value={this.state.accountNumberValid} />
              </div>
            </div>
            <div role="group">
              <label htmlFor="savings">Type of Account</label>
              <div className={`${styles.input} ${styles.radio}`}>
                <label htmlFor="savings">Savings</label>
                <input
                  type="radio"
                  name="bankAccountType"
                  id="savings"
                  required
                  value="savings"
                  onChange={this.handleForm}
                />
              </div>
              <div className={`${styles.input} ${styles.radio}`}>
                <label htmlFor="checking">Checking</label>
                <input
                  type="radio"
                  name="bankAccountType"
                  id="checking"
                  required
                  value="checking"
                  onChange={this.handleForm}
                />
              </div>
              <div className={`${styles.input} ${styles.radio}`}>
                <label htmlFor="other">Other</label>
                <input
                  type="radio"
                  name="bankAccountType"
                  id="other"
                  required
                  value="other"
                  onChange={this.handleForm}
                />
              </div>
            </div>
          </fieldset>

          <fieldset>
            <h4>Business / Trade References (Add up to three business)</h4>
            <div role="group">
              <label htmlFor="companyName0">Company Name</label>
              <div className={styles.input}>
                <input
                  type="text"
                  name="companyName0"
                  required
                  onChange={this.handleForm}
                  value={this.state.companyName0}
                  id="companyName0"
                  pattern=".{3,}"
                />
                <CheckMark value={this.state.companyName0Valid} />
              </div>
            </div>
            <div role="group">
              <label htmlFor="nameOfContact0">Name of Contact</label>
              <div className={styles.input}>
                <input
                  type="text"
                  name="nameOfContact0"
                  required
                  onChange={this.handleForm}
                  value={this.state.nameOfContact0}
                  id="nameOfContact0"
                  pattern=".{3,}"
                />
                <CheckMark value={this.state.nameOfContact0Valid} />
              </div>
            </div>
            <div role="group">
              <label htmlFor="phone0">Phone</label>
              <div className={styles.input}>
                <input
                  type="text"
                  name="phone0"
                  required
                  onChange={this.handleForm}
                  value={this.state.phone0}
                  id="phone0"
                  pattern=".{10,}"
                />
                <CheckMark value={this.state.phone0Valid} />
              </div>
            </div>
            <div role="group">
              <label htmlFor="address0">Address, City, State, Zip Code</label>
              <div className={styles.input}>
                <input
                  type="text"
                  name="address0"
                  required
                  onChange={this.handleForm}
                  value={this.state.address0}
                  id="address0"
                  pattern=".{3,}"
                />
                <CheckMark value={this.state.address0Valid} />
              </div>
            </div>
            <div role="group">
              <label htmlFor="email0">Email</label>
              <div className={styles.input}>
                <input
                  type="text"
                  name="email0"
                  required
                  onChange={this.handleForm}
                  value={this.state.email0}
                  id="email0"
                />
                <CheckMark value={this.state.email0Valid} />
              </div>
            </div>
          </fieldset>

          {this.state.refTwo && (
            <Slide right>
              <fieldset>
                <div className={styles.reference}>
                  <button onClick={() => this.removeReference(2)}>
                    <FaTimes />
                  </button>
                </div>
                <div role="group">
                  <label htmlFor="companyName1">Company Name</label>
                  <div className={styles.input}>
                    <input
                      type="text"
                      name="companyName1"
                      required
                      onChange={this.handleForm}
                      value={this.state.companyName1}
                      id="companyName1"
                    />
                    <CheckMark value={this.state.companyName1Valid} />
                  </div>
                </div>
                <div role="group">
                  <label htmlFor="nameOfContact1">Name of Contact</label>
                  <div className={styles.input}>
                    <input
                      type="text"
                      name="nameOfContact1"
                      required
                      onChange={this.handleForm}
                      value={this.state.nameOfContact1}
                      id="nameOfContact1"
                    />
                    <CheckMark value={this.state.nameOfContact1Valid} />
                  </div>
                </div>
                <div role="group">
                  <label htmlFor="phone1">Phone</label>
                  <div className={styles.input}>
                    <input
                      type="text"
                      name="phone1"
                      required
                      onChange={this.handleForm}
                      value={this.state.phone1}
                      id="phone1"
                    />
                    <CheckMark value={this.state.phone1Valid} />
                  </div>
                </div>
                <div role="group">
                  <label htmlFor="address1">
                    Address, City, State, Zip Code
                  </label>
                  <div className={styles.input}>
                    <input
                      type="text"
                      name="address1"
                      required
                      onChange={this.handleForm}
                      value={this.state.address1}
                      id="address1"
                    />
                    <CheckMark value={this.state.address1Valid} />
                  </div>
                </div>
                <div role="group">
                  <label htmlFor="email1">Email</label>
                  <div className={styles.input}>
                    <input
                      type="text"
                      name="email1"
                      required
                      onChange={this.handleForm}
                      value={this.state.email1}
                      id="email1"
                    />
                    <CheckMark value={this.state.email1Valid} />
                  </div>
                </div>
              </fieldset>
            </Slide>
          )}

          {this.state.refThree && (
            <Slide right>
              <fieldset>
                <div className={styles.reference}>
                  <button onClick={() => this.removeReference(3)}>
                    <FaTimes />
                  </button>
                </div>
                <div role="group">
                  <label htmlFor="companyName2">Company Name</label>
                  <div className={styles.input}>
                    <input
                      type="text"
                      name="companyName2"
                      required
                      onChange={this.handleForm}
                      value={this.state.companyName2}
                      id="companyName2"
                    />
                    <CheckMark value={this.state.companyName2Valid} />
                  </div>
                </div>
                <div role="group">
                  <label htmlFor="nameOfContact2">Name of Contact</label>
                  <div className={styles.input}>
                    <input
                      type="text"
                      name="nameOfContact2"
                      required
                      onChange={this.handleForm}
                      value={this.state.nameOfContact2}
                      id="nameOfContact2"
                    />
                    <CheckMark value={this.state.nameOfContact2Valid} />
                  </div>
                </div>
                <div role="group">
                  <label htmlFor="phone2">Phone</label>
                  <div className={styles.input}>
                    <input
                      type="text"
                      name="phone2"
                      required
                      onChange={this.handleForm}
                      value={this.state.phone2}
                      id="phone2"
                    />
                    <CheckMark value={this.state.phone2Valid} />
                  </div>
                </div>
                <div role="group">
                  <label htmlFor="address2">
                    Address, City, State, Zip Code
                  </label>
                  <div className={styles.input}>
                    <input
                      type="text"
                      name="address2"
                      required
                      onChange={this.handleForm}
                      value={this.state.address2}
                      id="address2"
                    />
                    <CheckMark value={this.state.address2Valid} />
                  </div>
                </div>
                <div role="group">
                  <label htmlFor="email2">Email</label>
                  <div className={styles.input}>
                    <input
                      type="text"
                      name="email2"
                      required
                      onChange={this.handleForm}
                      value={this.state.email2}
                      id="email2"
                    />
                    <CheckMark value={this.state.email2Valid} />
                  </div>
                </div>
              </fieldset>
            </Slide>
          )}

          {this.state.refTwo && this.state.refThree ? null : (
            <input
              type="button"
              className={styles.addAnotherBtn}
              value="Add Another"
              onClick={this.addFields}
            />
          )}

          <fieldset>
            <h4>Agreement</h4>
            <ol className={styles.ol}>
              <li>
                The undersigned agrees to guarantee full and prompt payment of
                all amounts due to Brophy, and agrees to any modifications,
                renewals and/or extensions of credit terms.
              </li>
              <li>
                All invoices are to be paid 30 days from date of invoice. After
                30 days, a late charge of 1.5% per month may be assessed.
              </li>
              <li>
                Claims arrising from invoices must be made within seven working
                days of receiving goods.
              </li>
              <li>
                Any cost of collection of unpaid invoices, shall be the
                responsibilty of the customer, without limitation, including but
                not limited to court costs, collection fees, and reasonable
                attorney fees and expenses.
              </li>
              <li>
                By submitting this application, you authorize Brophy Brothers
                Credit Application to make inquiries into banking and
                business/trade references that you have supplied. This
                application is a request for, not a guarantee of, credit terms.
              </li>
              <li>Sign your name and title in ALL CAPS below to submit:</li>
            </ol>
            <div role="group">
              <label htmlFor="nameAndTitle">Name and Title</label>
              <div className={styles.input}>
                <input
                  type="text"
                  name="nameAndTitle"
                  required
                  onChange={this.handleForm}
                  value={this.state.nameAndTitle}
                  id="nameAndTitle"
                  pattern="^(?!.*[a-z])(?!.*[0-9]).+$"
                />
                <CheckMark value={this.state.nameAndTitleValid} />
              </div>
            </div>
            <div role="group">
              <label htmlFor="date">Date</label>
              <div className={styles.input}>
                <input
                  type="date"
                  name="date"
                  required
                  onChange={this.handleForm}
                  value={this.state.date}
                  id="date"
                />
                <CheckMark value={this.state.dateValid} />
              </div>
            </div>
            <span>{this.state.infoMsg}</span>
          </fieldset>
          <input
            type="submit"
            className={styles.submitBtn}
            value={this.state.loading ? "Submitting..." : "Submit"}
          />
        </form>
      </section>
    )
  }
}

export default newForm
