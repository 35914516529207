import React from "react"
import { FaRegCheckCircle } from "react-icons/fa"
import styles from "./forms.module.scss"

const checkmark = ({ value }) => {
  return (
    <>
      {value !== null ? (
        value ? (
          <FaRegCheckCircle className={styles.valid} />
        ) : (
          <FaRegCheckCircle className={styles.invalid} />
        )
      ) : (
        <FaRegCheckCircle className={styles.default} />
      )}
    </>
  )
}

export default checkmark
